<template>
  <div class="agency-leads">
    <div class="card">
      <LeadTable
        :tableColumns="tableColumns"
        :tableData="tableData"
        :tablePagination="tablePagination"
        :isLoading.sync="isLoading"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
      ></LeadTable>
    </div>
  </div>
</template>

<script>
import { lead as LeadAPI } from "@/api";
import { getAddress } from "@/modules/Lead/utils";
import useLeadTable from "@/modules/Lead/composables/useLeadTable";

export default {
  setup(props, context) {
    const {
      isLoading,
      tableParams,
      tableData,
      tablePagination,
      filter,
      updateParams,
      onPageChange,
      onPerPageChange,
      getLeads
    } = useLeadTable(context, { getAPI: LeadAPI.getAgencyLeads });

    return {
      isLoading,
      tableParams,
      tableData,
      tablePagination,
      filter,
      updateParams,
      onPageChange,
      onPerPageChange,
      getLeads
    };
  },
  components: {
    LeadTable: () => import("@/modules/Lead/components/LeadTable")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      tableColumns: [
        {
          label: "Purpose",
          field: "leadPurpose",
          width: "200px"
        },
        {
          label: "Contact Person",
          field: "name"
        },
        {
          label: "Address",
          field: getAddress
        },
        {
          label: "Property Category",
          field: "propertyCategory"
        },
        {
          label: "Property Type",
          field: "propertyType.name"
        },
        {
          label: "Date Posted",
          field: "createdAt",
          formatFn: (date) => this.$dayjs(date).format("DD-MM-YYYY")
        }
      ]
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.getLeads();
    }
  }
};
</script>

<style lang="scss"></style>
