import { isEmpty } from "lodash";
export function getAddress({ address, state, postcode }) {
    let addrResult = isEmpty(address) ? "" : `${address}`;
    let stateResult = isEmpty(state) ? "" : `${state}`;
    let pcResult = isEmpty(postcode) ? "" : `${postcode}`;

    let comma1 = !isEmpty(address) && !isEmpty(state) ? ", " : "";
    let comma2 = !isEmpty(state) && !isEmpty(postcode) ? ", " : "";

    return `${addrResult}${comma1}${stateResult}${comma2}${pcResult}`;
}

export default {
    getAddress
};
