import { ref, unref } from "@vue/composition-api";
import { cloneDeep } from "lodash";
export default function useLeadTable(context, { getAPI }) {
    const tableParams = ref({
        page: 1,
        perPage: 30
    });
    const isLoading = ref(false);
    const filter = ref({});
    const tableData = ref([]);
    const tablePagination = ref({
        total: 0
    });

    // ============================ Table Functions ============================
    function updateParams(newProps) {
        tableParams.value = Object.assign({}, tableParams, newProps);
    }

    function onPageChange(params) {
        updateParams({ page: params.currentPage });
        getLeads();
    }

    function onPerPageChange(params) {
        updateParams({ perPage: params.currentPerPage });
        getLeads();
    }

    async function getLeads() {
        try {
            isLoading.value = true;

            let filteredParam = context.root.$cleanQueryParam(filter.value);

            let res = await getAPI({
                queries: filteredParam,
                page: unref(tableParams.page),
                perPage: unref(tableParams.perPage)
            });

            tableData.value = cloneDeep(res.data);
            tablePagination.value = cloneDeep(res.meta.pagination);

            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            context.root.$notify({
                group: "alert",
                type: "error",
                title: "Error",
                text: "Failed to fetch lead data. Please try again later."
            });
        }
    }

    return {
        isLoading,
        tableParams,
        tableData,
        tablePagination,
        filter,
        updateParams,
        onPageChange,
        onPerPageChange,
        getLeads
    };
}
